import React from "react";
import { degreesToRadians } from "popmotion";
import { motion } from "framer-motion-3d"
import { useGLTF } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";

export function StarIcon({ isCorrect, delay }) {
  const { nodes } = useGLTF("/star-icon.glb");
  const lights = [
    [2, 1, 4, 1],
    [8, 0, 4, 1]
  ];
  return (
    <Canvas
      resize={{ offsetSize: true }}
      dpr={[1, 2]}
      camera={{ position: [0, 0, 5.5], fov: 45 }}
    >
      {/* {lights.map(([x, y, z, intensity], i) => (
        <pointLight
          key={i}
          intensity={intensity}
          position={[x / 8, y / 8, z / 8]}
          color="#fff"
        />
      ))} */}
      <group dispose={null}>
        <motion.mesh
          geometry={nodes.Star.geometry}
          rotation={[Math.PI / 2, 0, degreesToRadians(360)]}
          scale={5}
          animate={[isCorrect ? "correct" : "incorrect"]}
          variants={{
            incorrect: {
              x: [0, 0],
              y: [0, 0],
              scale: 0.9
            },
            correct: {
              rotateZ: 0,
              rotateY: 0.3,
              scale: 1.5,
              transition: {
                rotateZ: { duration: 3, ease: "linear", repeat: Infinity },
                scale: {duration: 0.5,  delay: delay }
              }
            }
          }}
        >
          <meshPhongMaterial
            color="#ffdd00"
            emissive="#ff9500"
            specular="#fff"
            shininess="100"
          />
        </motion.mesh>
      </group>
    </Canvas>
  );
}


