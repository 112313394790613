import React, { useContext } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { PusherContext } from "../Context";

export const Button = styled(motion.div)`
  padding: 20px;
  width: 90%;
  font-size: 30px;
  border-radius: 20px;
  margin-bottom: 10px;
  color: white;
  text-align: center;
  background-color: #fff;
  color: #f27c00;
  position: relative;
  font-family: "CheltnhmitcBt";
  box-shadow: 1px 1px 3px rgba(#f27c00, 0.5);
  margin-left: auto;
  margin-right: auto;
`;


export const Container = styled(motion.div)`
  display:flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
`;

export const Confirm = (props) => {
  const { selectedMember, setIsConfirmed, isConfirmed, cancelSelection } =
    props;
  const { setUsername } = useContext(PusherContext);
  const handleOnClick = () => {
    setUsername(selectedMember.username);
    setIsConfirmed(!isConfirmed);
  };

  return (
    <Container
      as={motion.div}
      initial={{ scale: 0.8,
                  opacity: 0.5 }}
      animate={{ scale: 1 ,
        opacity: 1}}
      exit={{ scale: 0 ,
        opacity: 0.5}}
      transition={{ duration: 0.8 }}
    >
      <h3>
        Please confirm you are <br />
        {selectedMember.username}
      </h3>
      <div>
      <Button onClick={handleOnClick}>Yes I am!</Button>
      <Button onClick={cancelSelection}>O god no!</Button>
      </div>
    </Container>
  );
};
