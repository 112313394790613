import React, { useContext, useEffect, useState} from "react";
import Crowdwrapper from "./CrowdWrapper";
import { motion } from "framer-motion";
import { BackgroundImage } from "../Styles";
import styled from "styled-components";
import { PusherContext } from "../Context";
import useSound from "use-sound";

export const Stars = styled(motion.img)`
  display: flex;
  position: absolute;
  top: 0;
  height: 100vh;
  width: auto;
  top: 0;
  z-index: 2;
`;

export const Podium = styled(motion.img)`
  display: flex;
  position: absolute;
  bottom: 150px;
  width: 90%;
  z-index: 0;
`;

export const TopTeam = styled(motion.img)`
  display: flex;
  position: absolute;
  top: 10vh;
  z-index: 1;
`;

export const First = styled(motion.img)`
  display: flex;
  position: absolute;
  bottom: 230px;
  width: 100px;
  z-index: 1;
`;

export const Second = styled(motion.img)`
  display: flex;
  position: absolute;
  bottom: 190px;
  left: 25px;
  width: 90px;
  z-index: 1;
`;

export const Third = styled(motion.img)`
  display: flex;
  position: absolute;
  bottom: 180px;
  right: 25px;
  width: 90px;
  z-index: 1;
`;

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 10,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  }

};

export const Ceremony = () => {
  const { results, step, teams } = useContext(PusherContext);
  const [excitementLevel, setExcitementLevel] = useState(0)
  const first = results[0];
  const second = results[1];
  const third = results[2];

  const [playFirst] = useSound(
    require(`../assets/sfx/first-${Math.floor(Math.random() * 3) + 1
    }.mp3`),
    { volume: 0.5 }
  );


  let highestScore = 0;
  let teamWithHighestScore = "";

  for (let i = 0; i < teams.length; i++) {
    if (teams[i].score > highestScore) {
      highestScore = teams[i].score;
      teamWithHighestScore = teams[i].name;
    }
  }

  useEffect(() => {
      switch (step) {
        case "first":
          setExcitementLevel(0.5)
          break;
        case "second":
          setExcitementLevel(1.0)
          break;
        case "third": 
          setExcitementLevel(1.0)
          break;
        case "none":
          setExcitementLevel(0)
          break;
        case "final":
          setExcitementLevel(0)
          break;
        default:
          setExcitementLevel(0)
          break;
    }
  }, [step])

 

  return (
    <>
      <BackgroundImage
        as={motion.div}
        variants={variants}
        initial={false}
        animate={step === "credits" && "fadeOut"}
        image={"/assets/stadium.jpg"}
      />

      {results.length > 0 &&  (
        <>
          {step === "none" && teamWithHighestScore !== "" && (
            <TopTeam
              as={motion.img}
              variants={variants}
              initial={"closed"}
              animate={"open"}
              src={require(`../assets/teams/${teamWithHighestScore}.png`)}
            />
          )}
          {step === "first" && playFirst()}
          {
          step === "first" && (
            <>
              <Stars
                as={motion.img}
                src={require(`../assets/stars-stadium.gif`)}
              />
              <First
                as={motion.img}
                variants={variants}
                initial={"closed"}
                animate={"open"}
                src={require(`../assets/ducks/${first.image}`)}
              />{" "}
            </>
          )}
          {(step === "first" || step === "second") && (
            <Second
              variants={variants}
              as={motion.img}
              initial={"closed"}
              animate={"open"}
              src={require(`../assets/ducks/${second.image}`)}
            />
          )}
          {(step === "third" || step === "second" || step === "first") && (
            <Third
              as={motion.img}
              variants={variants}
              initial={"closed"}
              animate={"open"}
              src={require(`../assets/ducks/${third.image}`)}
            />
          )}
        </>
      )}
      <Podium as={motion.img} src={require(`../assets/podium.png`)} />
      <Crowdwrapper $excitementLevel={excitementLevel} />
    </>
  );
};
