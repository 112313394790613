import React, { useContext } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { PusherContext } from "../Context";
import styled from "styled-components";
import { Container, BackgroundImage } from "../Styles";
import Counter from "../components/Counter";
import PersonScore from "../components/PersonScore";

export const Button = styled(motion.div)`
  padding: 20px;
  width: 80%;
  font-size: 30px;
  border-radius: 20px;
  margin-bottom: 10px;
  text-align: center;
  background-color: #f27c00;
  color: #fff;
  position: relative;
  font-family: "CheltnhmitcBt";
  box-shadow: 1px 1px 3px rgba(#f27c00, 0.5);
  margin-left: auto;
  margin-right: auto;
`;

export const TeamLogo = styled(motion.div)`
  position: relative;
  padding: 20px;
  z-index: 1;
`;

export const TeamLogoScore = styled(motion.img)`
  width: 50%;
`;

export const TeamMember = styled(motion.div)`
  margin-right: 20px;
  height:30vh;
  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const MemberImage = styled(motion.img)``;

export const MemberWrapper = styled(motion.div)`
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index:1;
  ${({ $number }) => handleMemberPositioning($number)}
`;

export const TeamLineUp = styled(motion.ul)`
  display: flex;
`;



export const TeamList = styled(motion.ul)`
  display: flex;
  padding-left: 0;
  flex-wrap: wrap;
`;

export const TeamWrapperScore = styled(motion.li)`
  position: relative;
  width: 50%;
  z-index: 1;
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Answer = styled(motion.div)`
  width: 80%;
  font-size: 30px;
  color: black;
  text-align: center;
  position: relative;
  font-family: "CheltnhmitcBt";
  margin-left: auto;
  margin-right: auto;
`;

export const Foreground = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const WalkingChicken = styled(motion.img)`
  display: flex;
  position: absolute;
  width: 50px;
  z-index: 0;
  top: 70vh;
`;

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 10,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const variantsLineup = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const teamLogoVaraints = {
  open: {
    opacity: 1,
    scale: 1,
    transition: { type: "spring", stiffness: 100 },
  },
  closed: {
    opacity: 0,
    scale: 0,
    transition: { type: "spring", stiffness: 100 },
  },
};

const walkingChicken = {
  left: {
    left: "-50px",
    transition: { duration: "30" },
  },
  right: {
    left: "105%",
    transition: { duration: "15" },
  },
};

const handleMemberPositioning = ($number) => {
  switch ($number) {
    case 1:
      return "top: 60vh; left:25vw;";
    case 2:
      return "top: 65vh; left:5vw";
    case 3:
      return "top: 60vh; left:75vw";
    case 4:
      return "top: 65vh; left:40vw;";
    case 5:
      return "top: 60vh; left:60vw; z-index:0;";
    default:
      return;
  }
};

const Ready = () => {
  const { channelMembers, team, step, correctAnswer, scoreboard, teams } =
    useContext(PusherContext);

    let memberPlacement = 0
  return (
    <Container
      as={motion.div}
      key={"ready"}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
    >
      <BackgroundImage 
      as={motion.div}
      image={'/assets/farm-bg.jpg'}
      ></BackgroundImage>
      <AnimatePresence mode={"wait"}>
        {team && step !== "answer" && (
          <TeamLogo
            key={"avatar"}
            as={motion.img}
            variants={teamLogoVaraints}
            initial={"closed"}
            animate={"open"}
            exit={"closed"}
            src={require(`../assets/teams/${team}.png`)}
            alt={`Team ${team}`}
          />
        )}
        {step === "answer" && (
          <>
            <TeamList>
              {teams &&
                teams.map((team) => (
                  <TeamWrapperScore
                    key={team.name}
                    variants={teamLogoVaraints}
                    as={motion.div}
                    initial={"closed"}
                    animate={"open"}
                    exit={"closed"}
                  >
                    <TeamLogoScore
                      key={team.name}
                      as={motion.img}
                      src={require(`../assets/teams/${team.name}.png`)}
                      alt={`Team ${team.name}`}
                    />
                    <Counter oldScore={0} newScore={team.score} isTeam={true} />
                  </TeamWrapperScore>
                ))}
            </TeamList>
            <Answer
              key={"answer"}
              initial={"closed"}
              animate={"open"}
              exit={"closed"}
            >
              {correctAnswer}
            </Answer>
          </>
        )}
      </AnimatePresence>
      <WalkingChicken
        as={motion.img}
        variants={walkingChicken}
        initial={"left"}
        animate={"right"}
        src={require(`../assets/walkingchicken.gif`)}
      />
      <TeamLineUp
        as={motion.ul}
        variants={variantsLineup}
        initial={"closed"}
        animate={"open"}
      >
        { channelMembers && 
              Object.entries(channelMembers).map(([k, member]) => {
                if (member.name === "admin" || member.name === "Admin") return null;
                memberPlacement++
                return (
                  <MemberWrapper
                    as={motion.div}
                    $number={memberPlacement}
                    variants={variants}
                    key={k}
                  >
                    {step === "answer" && (
                      <PersonScore
                        ordering={scoreboard[k]?.time}
                        membersAnswer={scoreboard[k]}
                        correctAnswer={correctAnswer}
                      />
                    )}
                      <TeamMember>
                        <MemberImage
                          src={require(`../assets/ducks/${member.image}`)}
                          as={motion.img}
                          alt={member.username}
                          width="100%"/>
                      </TeamMember>
                  </MemberWrapper>
                ); 
              })     
          }
      </TeamLineUp>
    </Container>
  );
};
export default Ready;
