import { motion, AnimatePresence } from "framer-motion";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { PusherContext } from "../Context";
import Cookies from "js-cookie";
import constants from "../constants.json";

const animationState = {
  hidden: { opacity: 0, scale: 0.5 },
  visible: { opacity: 1, scale: 1 },
  transition: { opacity: 1, scale: 10 },
};



const TeamSelectWrapper = styled(motion.div)``;
const LoginCircleStyle = styled(motion.div)`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  background: white;
`;

export const Button = styled(motion.div)`
  padding: 20px;
  width: 80%;
  font-size: 30px;
  border-radius: 20px;
  margin-bottom: 10px;
  color: white;
  text-align: center;
  background-color: #fff;
  color: #f27c00;
  position: relative;
  font-family: "CheltnhmitcBt";
  box-shadow: 1px 1px 3px rgba(#f27c00, 0.5);
  margin-left: auto;
  margin-right: auto;
`;

export const Avatar = styled(motion.div)`
  padding: 20px;
`;

export const TeamSelect = (props) => {
  const navigate = useNavigate();
  const { setTeam } = useContext(PusherContext);
  const { selectedMember } = props;

  const [spinner, setSpinner] = useState(false);
  const [teamOption, setTeamOption] = useState(0);
  const [timerStarted, setTimerStarted] = useState(false);
  const teams = constants.teamsInitial.map(team => team.name);


  useEffect(() => {
    if (spinner && !timerStarted) {
      const spinningInterval = setInterval(() => {
        setTeamOption((teamOption) =>
          teamOption < teams.length - 1 ? teamOption + 1 : 0
        );
      }, 500);
      const handleTeamSelected = () => {
        axios.get(`${process.env.REACT_APP_SERVER_URL}/team-select`).then((res) => {
          console.log(res)
          clearInterval(spinningInterval);
          setTeamOption(0);
          setTeam(res.data.team);
          Cookies.set("team", res.data.team);
          navigate("/ready");
        });
      };
      setTimerStarted(true);
      setTimeout(handleTeamSelected, 5000);
    }
  }, [spinner, teamOption]);

  return (
    <TeamSelectWrapper
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      exit={{ scale: 0 }}
    >
      <h3>
        OK! <br />
        {selectedMember.username}
        <br />
        <br />
        Now Lets give you a team
      </h3>
      <AnimatePresence mode={"wait"}>
        <LoginCircleStyle
          key="spinner"
          as={motion.div}
          variants={animationState}
          initial={"hidden"}
          animate={"visible"}
          exit={{ scale: 10 }}
          onAnimationComplete={() => setSpinner(true)}
          transition={{ duration: 0.8 }}
        >
          {spinner && (
            <Avatar
              key={"avatar"}
              as={motion.div}
              animate={{ rotate: 3600 }}
              transition={{ ease: "easeInOut", duration: 5 }}
            >
              <img
                src={require(`../assets/teams/${teams[teamOption]}.png`)}
                alt={`Team ${teams[teamOption]}`}
                width="100%"
              />
            </Avatar>
          )}
        </LoginCircleStyle>
        <br />
      </AnimatePresence>
    </TeamSelectWrapper>
  );
};
