import React, { useContext, useRef, useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { PersonOption } from "./PersonOption";
import styled from "styled-components";
import { PusherContext } from "../Context";
import { Modal } from "./Modal";
import Cookies from "js-cookie";


const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};



export const MemberList = styled(motion.ul)`
  padding: 25px;
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: scroll;
`;

export const PersonSelect = () => {
  const { selectableUsers } = useContext(PusherContext);
  const [isClicked, setIsClicked] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isScrollable, setIsScrollable] = useState(false);

  useEffect(() => {
    setIsOpen(true)

  }, [])

  const handleOnClick = (member) => {
    setIsClicked(!isClicked);
    Cookies.set("username", member.username);
    Cookies.remove("token");
    setSelectedMember(member);
  };
  const scrollRef = useRef(null);
  return (
    <AnimatePresence mode="wait">
      <MemberList 
        as={motion.ul} 
        initial={"closed"}
        animate={isOpen ? "open" : "closed"}
        variants={variants} 
        ref={scrollRef}
        key={'memberlist'}
        onAnimationComplete={() => setIsScrollable(true)}
      >
        {selectableUsers &&
          selectableUsers.map((member) => (
            <PersonOption
              member={member}
              key={member._id}
              handleOnClick={handleOnClick}
              scrollRef={scrollRef}
              isScrollable={isScrollable}
            />
          ))}
      </MemberList>
      {isClicked && (
        <Modal
          key={'modal'}
          initial={"closed"}
          animate={isOpen ? "open" : "closed"}
          variants={variants} 
          selectedMember={selectedMember}
          cancelSelection={() => setIsClicked(!isClicked)}
        />
      )}
    </AnimatePresence>
  );
};
