import React from "react";
import { motion } from "framer-motion";
import styled, {keyframes} from "styled-components";


export const Crowd = styled(motion.div)`
  position: fixed;
  z-index: 2;
  display: flex;
  width: 100%;
  flex-direction: ${({layer}) => layer === 2 ? 'row-reverse' : 'row'};
  left: ${({layer}) => layer === 2 ? '40px' : '-10px'};
  bottom: ${({layer}) => layer === 2 ? '-110px' : '-100px'};
`;

const excitement = keyframes`
  0%, 100% {
    bottom: 0;
  }
  50% {
    bottom: 10px;
  }
`

const excitementReverse = keyframes`
  0%, 100% {
    bottom: 10px;
  }
  50% {
    bottom: 0px;
  }
`

export const CrowdMember = styled(motion.div)`
  z-index: 1;
  opacity: 1;
  height: auto;
  position:relative;
  ${({layer}) => {
    if(layer === 1){
      return `-webkit-filter: contrast(0) sepia(100%) hue-rotate(190deg) saturate(2000%) brightness(100%);
      filter: contrast(0) sepia(100%) hue-rotate(190deg) saturate(0%) brightness(50%);`
    }else{
      return `-webkit-filter: grayscale(100%) brightness(0);
      filter: grayscale(100%) brightness(0);`
    }
  }}
  animation: ${({number})=> number  % 2 === 0 ? excitement : excitementReverse } ${({$excitementLevel})=> $excitementLevel}s linear infinite;
`;




const Crowdwrapper = ({$excitementLevel}) => (

    <>
        <Crowd layer={1}>
            <CrowdMember as={motion.div}
            initial={false}
            $excitementLevel={$excitementLevel}
            animate={excitement}
            layer={1} number={4}>
            <img width="100%" src={require(`../assets/ducks/1.png`)} alt={'crowdmember'}/>
            </CrowdMember>
            <CrowdMember as={motion.div}
            initial={false}
            animate={excitement}
            $excitementLevel={$excitementLevel}
            layer={1} number={5}>
            <img width="100%" src={require(`../assets/ducks/2.png`)} alt={'crowdmember'}/>
            </CrowdMember>
            <CrowdMember as={motion.div}
            initial={false}
            animate={excitement}
            $excitementLevel={$excitementLevel}
            layer={1} number={6}>
            <img width="100%" src={require(`../assets/ducks/3.png`)} alt={'crowdmember'}/>
            </CrowdMember>
            <CrowdMember as={motion.div}
            initial={false}
            animate={excitement}
            $excitementLevel={$excitementLevel}
            layer={1} number={7}>
            <img width="100%" src={require(`../assets/ducks/4.png`)} alt={'crowdmember'}/>
            </CrowdMember>
            <CrowdMember as={motion.div}
            initial={false}
            animate={excitement}
            $excitementLevel={$excitementLevel}
            layer={1} number={8}>
            <img width="100%" src={require(`../assets/ducks/5.png`)} alt={'crowdmember'}/>
            </CrowdMember>
        </Crowd>

        <Crowd layer={2}>
            <CrowdMember as={motion.div}
            initial={false}
            animate={excitement}
            $excitementLevel={$excitementLevel}
            layer={2} number={4}>
            <img width="100%" src={require(`../assets/ducks/1.png`)} alt={'crowdmember'}/>
            </CrowdMember>
            <CrowdMember as={motion.div}
            initial={false}
            animate={excitement}
            $excitementLevel={$excitementLevel}
            layer={2} number={5}>
            <img width="100%" src={require(`../assets/ducks/2.png`)} alt={'crowdmember'}/>
            </CrowdMember>
            <CrowdMember as={motion.div}
            initial={false}
            animate={excitement}
          $excitementLevel={$excitementLevel}
            layer={2} number={6}>
            <img width="100%" src={require(`../assets/ducks/3.png`)} alt={'crowdmember'}/>
            </CrowdMember>
            <CrowdMember as={motion.div}
            initial={false}
            animate={excitement}
          $excitementLevel={$excitementLevel}
            layer={2} number={7}>
            <img width="100%" src={require(`../assets/ducks/4.png`)} alt={'crowdmember'}/>
            </CrowdMember>
            <CrowdMember as={motion.div}
            initial={false}
            animate={excitement}
          $excitementLevel={$excitementLevel}
            layer={2} number={8}>
            <img width="100%" src={require(`../assets/ducks/5.png`)} alt={'crowdmember'}/>
            </CrowdMember>
        </Crowd>
      </>
)

export default Crowdwrapper 