import React, { Suspense, useState, useEffect }from "react";
import CountUp from "react-countup";
import { motion } from "framer-motion";
import styled from "styled-components";
import { StarIcon } from "./StarIcon";
import constants from "../constants.json";
import useSound from "use-sound";
import gulp from "../assets/sfx/point.mp3";

const CounterWrapper = styled(motion.div)`
  display:flex;
  align-items:center;
  flex-direction: column;
  justify-content: center;
  font-family: 'MontserratAlternates';
  font-weight: 600;
  z-index: 2;
  font-size: 25px;
  width: 100%;
  position: absolute;
  top: -62px;
  left: -10px;
  z-index: 2;
`;

const StarStyles = styled(motion.div)`
display: block;
width: 600px;
height: 300px;
z-index: 1;
pointer-events: none;
left: calc(50% - 310px);
top: -200px;
transform-origin: center;
filter: grayscale(var(--button-star-greyscale))
    contrast(var(--button-star-contrast));
opacity: 1;
pointer-events:none;
position: absolute;
`

export default function PersonScore({ membersAnswer, correctAnswer }) {
  
  let usersScore = 0
  const usersAnswer = membersAnswer?.answer;
  const usersTime = membersAnswer?.time;
  const [showCounter, setShowCounter] = useState(false);
  if(usersAnswer === correctAnswer)
    usersScore = (constants.questionTimeframe - usersTime) * constants.questionMultiplier;
  const  duration = usersTime / 1000 / 4
  const  scoreDuration = usersTime / 100 / 4
  const [play,] = useSound( 
      gulp,
    { volume: 0.5
      }
    )
  
  
  useEffect(() => {
    setShowCounter(false)

    if( membersAnswer?.answer === correctAnswer){
      play()
      setTimeout(() => {
        setShowCounter(true)
      } , 2000)
    }
  }, [membersAnswer?.answer, correctAnswer])


  return (
    <>
      {(usersAnswer === correctAnswer) && (
        <>
          <StarStyles>
            <Suspense fallback={null}>
              <StarIcon isCorrect={(usersAnswer === correctAnswer)} delay={duration} />
            </Suspense>
          </StarStyles>
          <CounterWrapper 
          initial={{opacity: 0}}
          transition={{duration: 0.5}}
          animate={{opacity: 1}}>
            {showCounter && <CountUp duration={scoreDuration} className="counter" start={0} end={usersScore} />}
          </CounterWrapper>
        </>
      )}
    </>
  );

}
