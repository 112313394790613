import React from "react";
import styled from "styled-components";
import { PersonSelect } from "../components/PersonSelect";
import { motion } from "framer-motion";

const Question = styled.h2`
  font-size: 40px;
  margin-top: 30px;
  text-align: center;
  font-family: "CheltnhmitcBt";
`;

const Container = styled(motion.div)`
  display:contents;

`
const Login = () => {
  return (
    <Container
      as={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Question>Who are you?</Question>
      <PersonSelect />
    </Container>
  );
};

export default Login;
