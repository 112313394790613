import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";

const variants = {
  open: {
    x: 0,
    opacity: 1,
    transition: {
      x: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    x: 50,
    opacity: 0,
    transition: {
      x: { stiffness: 1000 },
    },
  },
};

export const Member = styled(motion.li)`
  list-style: none;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  font-size: 30px
  font-family: 'CheltnhmitcBt';
`;

export const Avatar = styled(motion.div)`

  opacity: ${(props) => (props.$isAllocated ? "0.5" : "1")};
  width: 300px;
  img{
    width: 100%;
    height: 100%;
    object-fit: contain
  }
`;

export const Name = styled(motion.div)`
  border-radius: 5px;
  width: 200px;
  flex: 1;
  font-size: 30px;
  font-family: "CheltnhmitcBt";
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  opacity: ${(props) => (props.$isAllocated ? "0.5" : "1")};
`;

export const TeamSelect = styled(motion.div)`
  width: 50px;
  height: 50px;
  background-color: red;
`;

export const PersonOption = ({ member, scrollRef, handleOnClick, isScrollable }) => {
  console.log(isScrollable)
  return (
    <Member
      as={motion.li}
      variants={variants}
      initial={isScrollable && { scale: 0.8, opacity: 0 }}
      whileInView={isScrollable && { scale: 1, opacity: 1 }}
      viewport={{ root: scrollRef }}
      amount={"all"}
      onClick={() => !member?.allocated && handleOnClick(member)}
    >
      <Avatar
      as={motion.div} 
      $isAllocated={member?.allocated}>
        <img
          src={require(`../assets/ducks/${member.image}`)}
          alt={member.username}
        />
      </Avatar>
      <Name 
      as={motion.div} 
      $isAllocated={member?.allocated}>
        {member.username}
      </Name>
    </Member>
  );
};
