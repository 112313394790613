import React, { useState, useContext, useEffect } from "react";
import { PusherContext } from "../Context";
import ReactCountdownClock from "react-countdown-clock";
import { motion } from "framer-motion";
import styled from "styled-components";
import { Container } from "../Styles";
import useSound from "use-sound"; 
import Cookies from "js-cookie";

export const BackgroundImage = styled(motion.div)`
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 0;
  overflow: hidden;
  opacity: 0.6;
  background: #ffffff;
`;

export const Foreground = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const ClockWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const Question = styled.h2`
  font-size: 28px;
  margin-top: 20px;
  margin-bottom: 30px;
  text-align: center;
  font-family: "CheltnhmitcBt";
  max-width: 375px;
`;

export const Button = styled(motion.button)`
  padding: 20px;
  width: 90%;
  font-size: 20px;
  border-radius: 20px;
  margin-bottom: 10px;
  text-align: center;
  position: relative;
  font-family: "CheltnhmitcBt";

  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  border-color: none;
  background-color: ${({ $selectedAnswer, $teamAnswers }) =>
    $selectedAnswer ? "#f27c00" : $teamAnswers ? "unset" : "#efefef4d"};
  color: ${({ $selectedAnswer }) => ($selectedAnswer ? "#fff" : "#000")};
  &:before {
    content: "";
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: ${({ $teamAnswers }) => $teamAnswers * 100}%;
    transition: all ease 0.2s;
    background-color: #ff8300;
    opacity: 0.6;
  }
`;

export const ButtonContent = styled.span`
  position: relative;
  z-index: 1;
`;
export const colors = ["orange", "red"];

const QuestionComponent = () => {
  const {
    questionSet,
    channelTeam,
    answers,
    me,
    scoreboard,
    setScoreboard,
    setAnswers
  } = useContext(PusherContext);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isDisabled, setDisabled] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [color, setColor] = useState(0);
  const [colorInterval, setColorInterval] = useState(null);
  const [play,] = useSound( 
    require(`../assets/sfx/${Cookies.get("team")}-${Math.floor(Math.random() * 3) + 1}.mp3`),
    { volume: 0.5 }
    )
  useEffect(() => {
    setStartTime(Date.now());
    const colorInterval = setInterval(() => {
      setColor((colorOption) =>
        colorOption < colors.length - 1 ? colorOption + 1 : 0
      );
    }, 2500);

    setColorInterval(colorInterval);
    return () => setStartTime(null);
  }, []);

  useEffect(() => {
    if (channelTeam) {
      channelTeam.bind("client-answerset", (data) => {
        let updatedScoreboard = scoreboard;
        if (!updatedScoreboard[data.member_id]) {
          const newArr = [...answers].map((newAnswer) => {
            if (newAnswer.answer === data.answer) newAnswer.score++;
            return newAnswer;
          });
          setAnswers(newArr);
          updatedScoreboard[data.member_id] = {
            answer: data.answer,
            time: data.time,
          };
          setScoreboard({ ...updatedScoreboard });
        }
      });
    }
    return () => channelTeam && channelTeam.unbind("client-answerset");
  }, [answers]);

  const handleButtonClick = (answer) => {
    if (!isDisabled) {
      setSelectedAnswer(answer);
      const updatedScoreboard = scoreboard;
      const endTime = Date.now();
      const timeTaken = endTime - startTime;
      updatedScoreboard[me.id] = { answer: answer, time: timeTaken };

      channelTeam.trigger("client-answerset", {
        answer,
        member_id: me.id,
        time: timeTaken,
      });
      play();
      setScoreboard({ ...updatedScoreboard });
      setDisabled(true);
    }
  };

  const handleTimeOut = () => {
    clearInterval(colorInterval);
    setDisabled(true);
  };
  return (
    questionSet &&
    answers && (
      <Container
        as={motion.div}
        key={"question"}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        <BackgroundImage as={motion.div}></BackgroundImage>
        <Foreground>
          <ClockWrapper>
            <ReactCountdownClock
              seconds={10}
              color={colors[color]}
              alpha={1}
              weight={10}
              fontSize={"auto"}
              showMilliseconds={true}
              size={100}
              onComplete={handleTimeOut}
            />
          </ClockWrapper>
          <Question>{questionSet.question}</Question>
          {answers.map((answer) => {
            return (
              <Button
                as={motion.button}
                key={answer.answer}
                onClick={() => !isDisabled && handleButtonClick(answer.answer)}
                $teamAnswers={answer.score / (channelTeam.members.count - 1)}
                $selectedAnswer={selectedAnswer === answer.answer}
              >
                <ButtonContent>{answer.answer}</ButtonContent>
              </Button>
            );
          })}
        </Foreground>
      </Container>
    )
  );
};

export default QuestionComponent;
