import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Login from "./routes/Login";
import Ready from "./routes/Ready";
import Welcome from "./routes/Welcome";
import QuestionComponent from "./routes/QuestionComponent";
import  {LineUp}  from "./routes/LineUp";
import Final from "./routes/Final";
import { Container, StatusBar } from "./Styles";
import { usePusherWrapper } from "./PusherWrapper";
import { PusherContext } from "./Context";
import Cookies from "js-cookie";
import constants from "./constants.json";
import { AnimatePresence } from "framer-motion";
import axios from "axios";

export default function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [channelPublic, setChannelPublic] = useState(null);
  const [channelTeam, setChannelTeam] = useState(null);
  const [team, setTeam] = useState(null);
  const [username, setUsername] = useState(null);
  const [channelMembers, setChannelMembers] = useState(null);
  const [status, setStatus] = useState("red");
  const [step, setStep] = useState(null);
  const [results, setResults] = useState([]);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isPusherInit, setIsPusherInit] = useState(false);
  const [questionSet, setQuestionSet] = useState(null);
  const [scoreboard, setScoreboard] = useState({});
  const [answers, setAnswers] = useState([]);
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const [me, setMe] = useState(null);
  const [pusher, setPusher] = useState(null);
  const [teams, setTeams] = useState(constants.teamsInitial);
  const [selectableUsers, setSelectableUsers] = useState([]);
  const [color, setColor] = useState("#cb6505");

  // Custom Hook that inititializes the pusher object.
  const pusherOBJ = usePusherWrapper();

  // Used for a reboot.
  useEffect(() => {
    if (pusher) {
      if (Cookies.get("username") || Cookies.get("team")) {  
        pusher.signin()
        pusher.bind("pusher:signin_success", () => {
            setTeam(Cookies.get("team"))
        });
      }
    }
  }, [isPusherInit]);

  // initialize the public channel
  useEffect(() => {
    axios
      .get(
      `${process.env.REACT_APP_SERVER_URL}/init-users`, 
      ).then((data) => setSelectableUsers(data.data));
    if (pusherOBJ) {
      setChannelPublic(pusherOBJ.subscribe("public-channel"));
      setPusher(pusherOBJ);
      setIsPusherInit(true);
    }
  }, [pusherOBJ]);

  // bind handler for public channel
  useEffect(() => {
    if (channelPublic && isPusherInit) {
      channelPublic.bind("pusher:subscription_succeeded", () => {
        setStatus(constants.state["connecting"]);
        channelPublic.bind("question", (data) => {
          setQuestionSet({ ...data });
        });
        channelPublic.bind("background-color", (data) => setColor(data.color));
        channelPublic.bind("trigger-step", (data) => setStep(data.step));
        channelPublic.bind("trigger-final", (data) => {
          setResults(data.users)
          //navigate('/final') 
        });
        channelPublic.bind("step", (data) => setStep(data.step));
        channelPublic.bind("logout", () => handleLogOut());
        channelPublic.bind("update-selected-users", (data) =>
          setSelectableUsers((selectableUsers) =>
            selectableUsers.map((user) =>
              user._id === data.id ? { ...user, allocated: true } : user
            )
          )
        );
        channelPublic.bind("answer", (data) => {
          setCorrectAnswer(data.correctAnswer);
          setTeams(data.teams);
        });
      });
    }
  }, [channelPublic, isPusherInit]);

  useEffect(() => {
    if (username) {
      pusher.signin();
    }
  }, [username]);

  // authenticate user
  useEffect(() => {
    if (team) {
      setChannelTeam(pusher.subscribe(`presence-${team}`));
    }
    if (channelTeam) {
      channelTeam.bind("pusher:subscription_succeeded", (members) => {
        setStatus(constants.state["connected"]);
        setStep("ready");
        setMe(members.me);
        setUsername(members.me.info.name);
        setChannelMembers(members.members);
        setIsAuthorized(true);
      });
      channelTeam.bind("pusher:subscription_error", () => {
        setStatus(constants.state["failed"]);
        Cookies.remove("token");
        Cookies.remove("team");
        setStep("login");
        navigate("/login");
      });
    }
  }, [team, channelTeam]);

  // is Authorized to team channel
  useEffect(() => {
    if (isAuthorized) {
      channelTeam.bind("pusher:member_added", () => {
        setChannelMembers({ ...channelMembers });
      });
      channelTeam.bind("pusher:member_removed", (member) => {
        delete channelMembers[member.id];
        setChannelMembers({ ...channelMembers });
      });
    }
  }, [isAuthorized]);

  // upon recieving new question set.
  useEffect(() => {
    if (questionSet) {
      setAnswers(
        questionSet.answers.map((prop) => {
          return { answer: prop, score: 0 };
        })
      );
    }
  }, [questionSet]);

   // on reload push user to ready on certain urls. 
   useEffect(() => {
    if(isAuthorized){
      const urls = ['/login', '/question', '/']
      urls.includes(location.pathname) &&  navigate('/ready')     
    } 
  }, [isAuthorized]);
 
 
  // set Step state.
  useEffect(() => {
    if (step === "question") {
      setScoreboard([]);
      navigate("/question");
    }
    if (step === "answer") {
      navigate("/answer");
    }
    if (step === "final") {
      navigate("/final");
    }
    if (step === "welcome" || step === "login") {
      navigate("/");
    }
  }, [step]);

  const handleLogOut = () => {
    setChannelTeam(null);
    setIsAuthorized(false);
    setChannelMembers(null);
    setTeam(null);
    setIsPusherInit(null);
    setStatus("red");
    setStep("login");
    setTeam(null);
    setUsername(null);
    setScoreboard([]);
    Cookies.remove("token");
    Cookies.remove("username");
    Cookies.remove("team");
    navigate("/login");
    document.location.reload();
  };

  return (
    <PusherContext.Provider
      value={{
        color,
        channelTeam,
        team,
        username,
        channelMembers,
        questionSet,
        scoreboard,
        correctAnswer,
        answers,
        pusher,
        me,
        teams,
        step,
        results,
        isPusherInit,
        selectableUsers,
        setIsAuthorized,
        setIsPusherInit,
        setSelectableUsers,
        setTeam,
        setUsername,
        setChannelTeam,
        setScoreboard,
        setAnswers,
        setTeams,
      }}
    >
      <Container>
        <StatusBar primary={status} />
        { isAuthorized && <Ready /> }
        <AnimatePresence mode="wait">
          <Routes key={location.pathname} location={location}>
            <Route path="/" element={<Welcome />} />
            <Route path="/login" element={<Login />} />
            <Route path="/question" element={<QuestionComponent />} />
  
            <Route path="/final" element={<Final />} />
            <Route path="/lineup" element={<LineUp/>} />
          </Routes>
        </AnimatePresence>
      </Container>
    </PusherContext.Provider>
  );
}
