import React, { useContext } from "react";
import { Container } from "../Styles";
import { motion } from "framer-motion";
import { PusherContext } from "../Context";
import { Ceremony } from "../components/Ceremony";
import { Credits } from "../components/Credits";

const Final = () => {
  const { step } = useContext(PusherContext);

  return (
    <Container
      as={motion.div}
      key={"ready"}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
    >

      {step !== 'credits' ? <Ceremony/> : <Credits/>}
    </Container>
  );
};
export default Final;
