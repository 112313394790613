import React, { useContext } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { PusherContext } from "../Context";
import { BackgroundImage } from "../Styles";
import useSound from "use-sound"; 

export const Avatar = styled(motion.div)`
  width: 40px;
  flex: 40px 0;
`;

export const Name = styled(motion.div)`
  border-radius: 5px;
  width: 200px;
  flex: 1;
  font-size: 20px;
  color: white;
  font-family: "CheltnhmitcBt";
`;


export const MemberList = styled(motion.ul)`
  padding: 25px;
  width: 100%;
  z-index:2;
  position: fixed;
`;

export const Member = styled(motion.li)`
  list-style: none;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 30px
  color: white;
  font-family: 'CheltnhmitcBt';
`;

export const Score = styled(motion.div)`
  color: white;
  font-size: 20px;
`

export const Team = styled(motion.div)`

`

export const TeamLogo = styled(motion.img)`
  max-width: 50px;
`;


const variants = {
  start: {
    transform: 'translateY(20%)',
    transition: {
      duration: 10
    }
  },
  end: {
    transform: 'translateY(-100%)'
  }
}


export const Credits = () => {
    const { results, step } = useContext(PusherContext);
    const [play,] = useSound( 
      require(`../assets/sfx/credits-music-slack.mp3`),
      { volume: 0.5 }
      )


    // const first = results[0];
    // const second = results[1];
    // const third = results[3];

      play();

    return (
    <>
      <BackgroundImage as={motion.div} initial={false} animate={step === 'credits' && ('fadeOut')} image={""} />
      <>
      <MemberList 
        variants={variants}
        initial={"start"}
        animate={"end"}
        transition={{duration: 30 , ease: "linear"}}
        as={motion.ul}     
      >
        {results &&
          results.filter((member) =>  member.username !== 'Admin').map((member) => (
              <Member as={motion.li}
                key={member.username}
              >
             
              <Name 
              as={motion.div} 
              >
                {member.username}
              </Name>
              <Score>{parseInt(member.score)}pts</Score>
              { member.team && <Team>
                    <TeamLogo     
                      as={motion.img}
                      src={require(`../assets/teams/${member.team}.png`)}
                      alt={`Team ${member.team}`}
                    />
              </Team>}
            </Member>
          ))}
      </MemberList>
      </>
    </>
    )

}