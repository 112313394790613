import Pusher from "pusher-js";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";

export const usePusherWrapper = () => {
  const [isInit, setIsInit] = useState(false);
  const [pusher, setPusher] = useState(false);
  const paramsProviderHandler = () => {
    return { username: Cookies.get("username") };
  };
  useEffect(() => {
    if (!isInit) {
      Pusher.logToConsole = true;
      Pusher.Runtime.createXHR = function () {
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        return xhr;
      };
      const pusherOBJ = new Pusher(process.env.REACT_APP_PUSHER_APP_ID, {
        cluster: "ap4",
        forceTLS: false,
        channelAuthorization: {
          endpoint: `${process.env.REACT_APP_SERVER_URL}/authorize`,
          transport: "ajax",
        },
        userAuthentication: {
          endpoint: `${process.env.REACT_APP_SERVER_URL}/authenticate`,
          transport: "ajax",
          paramsProvider: paramsProviderHandler,
        },
      });
      setPusher(pusherOBJ);
      setIsInit(!isInit);
    }
  }, [isInit]);

  return isInit && pusher;
};
