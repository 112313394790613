import React, { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const animationState = {
  hidden: { opacity: 0, scale: 0.5 },
  visible: { opacity: 1, scale: 1 },
  transition: { opacity: 0, scale: 0 },
};

const LoginCircleStyle = styled(motion.div)`
  width: 250px;
  height: 250px;
  border-radius: 50%
  background-color:white;
`;

export const LoginCircle = () => {
  const [isClicked, setIsClicked] = useState(false);
  const navigate = useNavigate();
  const animationCompleteHandler = () => {
    if (isClicked) {
      navigate("/login");
    }
  };

  return (
    <LoginCircleStyle  
      as={motion.div}
      onClick={() => setIsClicked(true)}
      variants={animationState}
      initial={"hidden"}
      animate={isClicked ? "transition" : "visible"}
      onAnimationComplete={animationCompleteHandler}
      transition={{
        duration: isClicked ? 1.0 : 0.8,
      }}
    >
    <img
      src={require(`../assets/know-your-suiteys.png`)}
      alt={'Suite as candy'}
      width="100%"
    />
    </LoginCircleStyle>
  );
};
