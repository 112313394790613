import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { normalize } from "styled-normalize";
import App from "./App";
import { createGlobalStyle } from "styled-components";
import cheltnhmitcBt from "./assets/fonts/cheltnhmitc_bt-webfont.woff2";
import MontserratAlternates from "./assets/fonts/MontserratAlternates-SemiBold.woff";  

export const GlobalStyle = createGlobalStyle`
  ${normalize}

  *, *::after, *::before{
    box-sizing: border-box;
  }

  @font-face {
    font-family: 'CheltnhmitcBt';
    src: url(${cheltnhmitcBt}) format('woff2');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'MontserratAlternates';
    src: url(${MontserratAlternates}) format('woff');
    font-weight: 600;
    font-style: normal;
  }

  canvas {
    position: absolute;
    width: 100%;
    height: 100%;
  }
 
`;

ReactDOM.createRoot(document.getElementById("root")).render(
  <BrowserRouter>
    <GlobalStyle />
    <App />
  </BrowserRouter>
);
