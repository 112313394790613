import React, { useContext } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { PusherContext } from "../Context";



export const MemberList = styled(motion.ul)`
  display:flex; 
  width: 100%;
  flex-wrap:wrap;
  z-index:200;
`
export const Member = styled(motion.li)`
  list-style: none;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 30px
  color: white;
  font-family: 'CheltnhmitcBt';
`;

export const Avatar = styled(motion.div)`
  margin-right: 20px;

  img{
    width: 100%;
    height: 100%;
    object-fit: cover; /*magic*/
  }
`;



export const LineUp = () => {
    const { results } = useContext(PusherContext);

    // const first = results[0];
    // const second = results[1];
    // const third = results[3];
    console.log('results', results)
    return (
    <>

      <MemberList>
        {results &&
          results.filter((member) =>  member.username !== 'Admin').map((member) => (
              <Member as={motion.li}
                key={member.username}
              >
            
             <Avatar as={motion.div}>
                <img
                  src={require(`../assets/ducks/${member.image}`)}
                  alt={member.username}
                  width="100%"
                />
              </Avatar>
            </Member>
          ))}
      </MemberList>
    </>
    )

}

