import React, {useState, useEffect}from "react";
import CountUp from "react-countup";
import { motion } from "framer-motion";
import styled from "styled-components";
//import Sound from 'react-sound';
//import Ascend from '../assets/sfx/ascend-1.mp3'
const CounterWrapper = styled(motion.div)`
    display:flex;
    position: absolute;
    align-items:center;
    justify-content: center;
    font-family: 'MontserratAlternates';
    font-weight: 600;
    width: calc(100% - 15px);
    height: 10vh;
    border-radius: 36px;
    background-color: white;
    margin-bottom: 15px;
    box-shadow: 0px 40px 80px 0px rgba(0, 0, 0, 0.05),
    inset 0px -10px 20px 0px rgba(0, 0, 0, 0.05),
    0px 10px 20px 0px rgba(0, 0, 0, 0.05);
    font-size: 30px;
    z-index:-1;
    padding-left: 25vw;
    padding-right: 10vw;
`;

export default function Counter({ oldScore, newScore, isTeam }) {
  const [, setIsPlaying] = useState(false) 
  useEffect(() => {
    setIsPlaying(true)
  }, [])
  return (
    <CounterWrapper $isTeam={isTeam}>
      {/* <Sound
          url={Ascend}
          playStatus={isPlaying ? Sound.status.PLAYING : Sound.status.STOPPED}
      /> */}
      <CountUp duration={newScore / 10} className="counter" start={oldScore} end={newScore} />
    </CounterWrapper>
  );
}
