import React from "react";
import { motion } from "framer-motion";
import {  LogoWrapper} from "../Styles";

const icon = {
  hidden: {
    pathLength: 0,
    fill: "rgba(255, 255, 255, 0)",
  },
  visible: {
    pathLength: 1,
    fill: "rgba(255, 255, 255, 1)",
  },
};
export const Logo = (props) => {
  let logoDisplay = props.logoDisplay;
  return (
    <LogoWrapper
    initial={false}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    >
    <motion.svg
      initial={false}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      version="1.1"
      id="Isolation_Mode"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="-271.2 401.7 146.6 38.5"
    >
      <g>
        <g>
          <g>
            <motion.path
              variants={icon}
              initial="hidden"
              animate={logoDisplay}
              transition={{
                default: { duration: 0.1, ease: "easeInOut" },
                fill: { duration: 0.1, ease: [1, 0, 0.8, 1] },
              }}
              d="M-257.1,422.8l0-3.2l0.1-10.3l2.7,0.3l0,1.7c0.5-1.2,1.8-1.7,3.2-1.6c1.8,0.1,3.2,0.6,3.8,2.3
				c0.6-1.3,1.6-1.8,3.1-1.7c2.9,0.2,3.8,1.4,3.8,4.1v1.7l0,5.3l-1.7,0.2c0-1.8,0.1-3.6,0.1-5.4l0-1.2c0-1.3-0.3-2.8-1.9-2.9
				c-0.7,0-1.6,0.1-2,0.7c-1,1.4-0.7,7.4-0.7,9.4c-0.7,0.1-1.5,0.1-2.3,0.2c0-1.3,0.2-8.7-0.1-9.5c-0.4-0.9-1.3-1.3-2.4-1.3
				c-3.5-0.2-2.8,5.1-2.8,7.2l0,4l-1.4,0L-257.1,422.8z"
            />
            <motion.path
              variants={icon}
              initial="hidden"
              animate={logoDisplay}
              transition={{
                default: { duration: 0.2, ease: "easeInOut" },
                fill: { duration: 0.2, ease: [1, 0, 0.8, 1] },
              }}
              d="M-241.3,436.9c-1.5,0-3,0-4.5,0c-1-0.1-1.7-0.8-1.9-1.7c-0.4-3.1-0.7-6.3-0.6-9.4c2.4-0.3,4.7-0.6,6.9-1.1
				l0,0.1c0.4,0.9,1.2,1.7,2.1,2.2c0.7,0.4,2.7,0.6,5.9,0.7c1.4,0,2.5,0.1,3.3,0.1c2.1,0.1,3.3,0.4,3.8,0.9c0.5,0.5,0.7,1.4,0.7,2.9
				c0,1.5-0.3,2.5-0.9,3.1c-0.6,0.6-2.3,1-5.1,1.2c-2.6,0.2-4.5,0.1-5.4-0.4c-1-0.5-1.4-1.5-1.4-3.1v-0.6l-4,0.2v0.7
				C-242.3,434.5-242,435.9-241.3,436.9z M-218.7,432.5c-0.1,0.6-0.4,1.2-0.9,1.4c-1.1,0.3-2.2,0.6-3.4,0.9c0.3-0.8,0.5-2,0.5-3.6
				c0-1.6-0.2-2.7-0.5-3.6c-0.4-0.8-1-1.5-1.8-1.9c-0.4-0.2-1.1-0.4-2-0.5c-1-0.1-2.4-0.3-4.2-0.4c-0.3,0-0.8,0-1.4-0.1
				c-2.4-0.1-4-0.4-4.7-0.8c-0.1-0.1-0.2-0.2-0.3-0.3c0.5-0.2,0.8-0.8,0.9-1.4c0.1-1.6,0.2-3.1,0.3-4.7c0.9-0.2,2.4-0.2,4.4-0.1
				c2.5,0.2,4.1,0.6,4.9,1c0.8,0.4,1.2,1.2,1.2,2.1v0.2l2.9,0.2c0-1-0.1-1.8-0.4-2.6c1.4,0.3,2.7,0.6,3.9,1c0.5,0.2,0.8,0.8,0.9,1.4
				"
            />
            <motion.path
              variants={icon}
              initial="hidden"
              animate={logoDisplay}
              transition={{
                default: { duration: 0.3, ease: "easeInOut" },
                fill: { duration: 0.3, ease: [1, 0, 0.8, 1] },
              }}
              d="M-261,406.6c-0.9,0.1-1.4,0.8-1.5,1.6c-0.5,5.4-0.5,10.9,0.2,16.3c0.1,0.8,0.7,1.5,1.5,1.6
				c9.2,0.4,17-0.5,23.4-2.5c0.5-0.2,0.8-0.8,0.8-1.4c0.4-4,0.4-7.9,0.1-11.9c-0.1-0.6-0.4-1.2-0.8-1.4
				C-243.8,407.2-251.7,406.3-261,406.6z M-265.1,404.8c12.3,0,22.2,1.3,29.9,3.5c0.6,0.2,0.9,0.9,1,1.6c0.3,4.3,0.3,8.7-0.1,13
				c-0.1,0.7-0.4,1.3-1,1.5c-7.6,2.3-17.4,3.6-29.6,3.5c-1.1-0.1-1.9-0.9-2.1-1.9c-0.9-6.4-1-12.9-0.2-19.3
				C-267.1,405.8-266.3,404.9-265.1,404.8z"
            />
          </g>
        </g>
      </g>
      <g>
        <g>
          <g>
            <motion.path
              variants={icon}
              initial="hidden"
              animate={logoDisplay}
              transition={{
                default: { duration: 0.4, ease: "easeInOut" },
                fill: { duration: 0.4, ease: [1, 0, 0.8, 1] },
              }}
              d="M-202.1,430.9v-5.3c0-0.4,0-0.7-0.1-0.9c-0.1-0.2-0.2-0.5-0.3-0.6c-0.1-0.2-0.3-0.3-0.4-0.3
				c-0.2-0.1-0.3-0.1-0.5-0.1c-0.3,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.5-0.3,0.8c-0.1,0.3-0.1,0.6-0.1,0.9
				v4.9h-2.2v-5.3c0-0.7-0.1-1.3-0.4-1.5c-0.3-0.3-0.6-0.4-0.9-0.4c-0.3,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5
				s-0.2,0.5-0.3,0.8c-0.1,0.3-0.1,0.6-0.1,0.9v4.9h-2.2v-8.5h2v1.3h0c0.5-1.1,1.4-1.7,2.6-1.7c0.6,0,1.1,0.1,1.4,0.4
				c0.4,0.3,0.7,0.7,0.9,1.3c0.3-0.5,0.7-1,1.2-1.3c0.5-0.3,1-0.4,1.7-0.4c0.8,0,1.4,0.2,1.9,0.6c0.5,0.4,0.7,1.1,0.7,1.9v6.3
				H-202.1z"
            />
            <motion.path
              variants={icon}
              initial="hidden"
              animate={logoDisplay}
              transition={{
                default: { duration: 0.5, ease: "easeInOut" },
                fill: { duration: 0.5, ease: [1, 0, 0.8, 1] },
              }}
              d="M-196.4,427.1c0,0.3,0,0.6,0.1,0.9c0.1,0.3,0.2,0.6,0.3,0.8s0.4,0.4,0.6,0.6c0.2,0.2,0.6,0.2,0.9,0.2
				c0.5,0,0.9-0.1,1.2-0.4c0.3-0.3,0.5-0.6,0.6-1.1h2.2c-0.1,0.6-0.3,1.1-0.6,1.5s-0.6,0.7-0.9,0.9c-0.3,0.2-0.7,0.4-1.1,0.5
				c-0.4,0.1-0.8,0.1-1.2,0.1c-0.7,0-1.4-0.1-1.9-0.4c-0.5-0.2-1-0.6-1.3-1c-0.3-0.4-0.6-0.9-0.8-1.5c-0.2-0.6-0.3-1.1-0.3-1.8
				c0-0.6,0.1-1.2,0.3-1.8c0.2-0.6,0.4-1,0.8-1.5c0.3-0.4,0.8-0.8,1.3-1c0.5-0.2,1.1-0.4,1.8-0.4c0.8,0,1.5,0.1,2,0.4
				c0.5,0.3,1,0.6,1.3,1.1c0.3,0.5,0.5,1,0.7,1.6c0.1,0.6,0.2,1.3,0.1,2H-196.4z M-192.3,425.5c-0.1-0.6-0.2-1.1-0.5-1.4
				c-0.3-0.3-0.7-0.5-1.3-0.5c-0.7,0-1.2,0.2-1.5,0.5c-0.3,0.3-0.5,0.8-0.6,1.4H-192.3z"
            />
            <motion.path
              variants={icon}
              initial="hidden"
              animate={logoDisplay}
              transition={{
                default: { duration: 0.6, ease: "easeInOut" },
                fill: { duration: 0.6, ease: [1, 0, 0.8, 1] },
              }}
              d="M-183,430.9v-1.3h0c-0.2,0.5-0.6,1-1.1,1.2c-0.5,0.3-1.1,0.4-1.6,0.4c-0.4,0-0.8-0.1-1.2-0.3
				c-0.4-0.2-0.8-0.5-1.1-0.9c-0.3-0.4-0.6-0.9-0.8-1.4c-0.2-0.6-0.3-1.3-0.3-2c0-0.8,0.1-1.4,0.3-2c0.2-0.6,0.5-1.1,0.8-1.4
				c0.3-0.4,0.7-0.7,1.1-0.9c0.4-0.2,0.8-0.3,1.2-0.3c0.6,0,1.1,0.1,1.6,0.4c0.4,0.3,0.8,0.7,1.1,1.1h0v-5.8h2.2v13.1H-183z
				 M-183,426.7c0-0.3,0-0.6-0.1-1c-0.1-0.4-0.2-0.7-0.3-1c-0.2-0.3-0.4-0.6-0.6-0.8c-0.3-0.2-0.6-0.3-1-0.3c-0.3,0-0.6,0.1-0.8,0.3
				c-0.2,0.2-0.4,0.4-0.6,0.7s-0.3,0.6-0.4,1c-0.1,0.4-0.1,0.7-0.1,1.1c0,0.4,0,0.7,0.1,1.1c0.1,0.4,0.2,0.7,0.4,1
				c0.2,0.3,0.4,0.5,0.6,0.7c0.2,0.2,0.5,0.3,0.8,0.3c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.5,0.6-0.8c0.2-0.3,0.3-0.6,0.3-1
				C-183,427.3-183,427-183,426.7z"
            />
            <motion.path
              variants={icon}
              initial="hidden"
              animate={logoDisplay}
              transition={{
                default: { duration: 0.7, ease: "easeInOut" },
                fill: { duration: 0.7, ease: [1, 0, 0.8, 1] },
              }}
              d="M-179,420.7v-2.2h2.2v2.2H-179z M-176.9,422.4v8.5h-2.2v-8.5H-176.9z"
            />
            <motion.path
              variants={icon}
              initial="hidden"
              animate={logoDisplay}
              transition={{
                default: { duration: 0.8, ease: "easeInOut" },
                fill: { duration: 0.8, ease: [1, 0, 0.8, 1] },
              }}
              d="M-169.5,430.9c-0.1-0.2-0.2-0.4-0.2-0.7c-0.1-0.2-0.1-0.5,0-0.7h0c-0.3,0.5-0.7,1-1.1,1.3
				c-0.5,0.3-1,0.5-1.6,0.5c-0.9,0-1.7-0.3-2.2-0.8c-0.5-0.5-0.8-1.2-0.8-2.1c0-0.7,0.1-1.2,0.4-1.6c0.3-0.4,0.6-0.6,1.1-0.8
				c0.4-0.2,0.9-0.3,1.4-0.3c0.5,0,1-0.1,1.4-0.1c0.4,0,0.8-0.1,1.1-0.3c0.3-0.1,0.4-0.4,0.4-0.8c0-0.4-0.2-0.7-0.5-0.8
				c-0.3-0.2-0.7-0.2-1.2-0.2c-0.2,0-0.3,0-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.2c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.1,0.3-0.1,0.5h-2.1
				c0-0.5,0.1-0.9,0.3-1.3c0.2-0.3,0.5-0.6,0.8-0.8c0.3-0.2,0.7-0.4,1.1-0.4c0.4-0.1,0.9-0.1,1.3-0.1c1.3,0,2.3,0.3,2.9,0.9
				c0.6,0.6,1,1.6,1,2.9v3.4c0,0.5,0,0.8,0.1,1.1c0.1,0.2,0.2,0.4,0.4,0.6H-169.5z M-171.8,429.7c0.5,0,1-0.1,1.2-0.3
				c0.3-0.2,0.5-0.5,0.6-0.9c0.1-0.4,0.2-0.8,0.2-1.2c0-0.4,0-0.8,0-1.2h0c-0.1,0.2-0.3,0.4-0.5,0.5c-0.2,0.1-0.4,0.2-0.7,0.3
				c-0.3,0.1-0.5,0.1-0.8,0.2c-0.3,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.2-0.5,0.4c-0.1,0.2-0.2,0.4-0.2,0.7c0,0.5,0.1,0.8,0.4,1
				C-172.6,429.6-172.2,429.7-171.8,429.7z"
            />
            <motion.path
              variants={icon}
              initial="hidden"
              animate={logoDisplay}
              transition={{
                default: { duration: 0.9, ease: "easeInOut" },
                fill: { duration: 0.9, ease: [1, 0, 0.8, 1] },
              }}
              d="M-162.1,428.4c0,0.3,0.1,0.5,0.2,0.6c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.4,0.2,0.6,0.2
				c0.2,0,0.4,0.1,0.7,0.1c0.5,0,1-0.1,1.3-0.3c0.3-0.2,0.5-0.4,0.5-0.8c0-0.3-0.1-0.5-0.4-0.7c-0.3-0.1-0.6-0.3-1-0.3
				c-0.4-0.1-0.8-0.2-1.3-0.3c-0.5-0.1-0.9-0.2-1.3-0.4c-0.4-0.2-0.7-0.5-1-0.8c-0.3-0.3-0.4-0.8-0.4-1.4c0-0.5,0.1-0.9,0.4-1.2
				c0.2-0.3,0.5-0.6,0.9-0.8c0.4-0.2,0.8-0.4,1.2-0.5c0.5-0.1,0.9-0.2,1.3-0.2c0.5,0,0.9,0.1,1.4,0.2c0.4,0.1,0.8,0.3,1.1,0.5
				c0.3,0.2,0.6,0.5,0.8,0.9c0.2,0.4,0.3,0.8,0.3,1.3h-2c0-0.4-0.2-0.7-0.5-1c-0.3-0.2-0.7-0.3-1.2-0.3c-0.2,0-0.3,0-0.5,0
				c-0.2,0-0.4,0.1-0.5,0.2c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.3-0.2,0.5c0,0.3,0.1,0.5,0.4,0.6c0.3,0.1,0.6,0.2,1,0.3
				c0.4,0.1,0.8,0.1,1.3,0.2c0.5,0.1,0.9,0.2,1.3,0.4c0.4,0.2,0.7,0.4,1,0.8c0.3,0.3,0.4,0.8,0.4,1.4c0,0.6-0.1,1-0.4,1.4
				s-0.6,0.7-1,0.9c-0.4,0.2-0.8,0.4-1.2,0.5c-0.4,0.1-0.9,0.1-1.2,0.1c-0.5,0-1.1-0.1-1.5-0.2c-0.5-0.1-0.9-0.3-1.3-0.5
				c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.8-0.4-1.3v0H-162.1z"
            />
            <motion.path
              variants={icon}
              initial="hidden"
              animate={logoDisplay}
              transition={{
                default: { duration: 1, ease: "easeInOut" },
                fill: { duration: 1, ease: [1, 0, 0.8, 1] },
              }}
              d="M-147.1,422.4v8.5h-2.1v-1.4h0c-0.3,0.6-0.7,1-1.2,1.3c-0.5,0.3-1.1,0.4-1.8,0.4c-0.4,0-0.8-0.1-1.2-0.2
				c-0.4-0.1-0.7-0.3-0.9-0.6c-0.2-0.3-0.4-0.6-0.6-1c-0.1-0.4-0.2-0.9-0.2-1.5v-5.6h2.2v5.4c0,0.5,0.1,1,0.4,1.4
				c0.2,0.4,0.7,0.5,1.3,0.5c0.6,0,1.1-0.2,1.4-0.6c0.3-0.4,0.5-1,0.5-1.7v-5H-147.1z"
            />
            <motion.path
              variants={icon}
              initial="hidden"
              animate={logoDisplay}
              transition={{
                default: { duration: 1.1, ease: "easeInOut" },
                fill: { duration: 1.2, ease: [1, 0, 0.8, 1] },
              }}
              d="M-145.3,420.7v-2.2h2.2v2.2H-145.3z M-143.1,422.4v8.5h-2.2v-8.5H-143.1z"
            />
            <motion.path
              variants={icon}
              initial="hidden"
              animate={logoDisplay}
              transition={{
                default: { duration: 1.3, ease: "easeInOut" },
                fill: { duration: 1.3, ease: [1, 0, 0.8, 1] },
              }}
              d="M-138.7,419.3v3h2v1.7h-2v3.5c0,0.3,0,0.5,0,0.7c0,0.2,0.1,0.3,0.2,0.5c0.1,0.1,0.2,0.2,0.3,0.2
				c0.1,0,0.3,0.1,0.6,0.1c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0,0.4,0v1.8c-0.3,0-0.5,0-0.8,0c-0.2,0-0.5,0-0.8,0c-0.6,0-1.1-0.1-1.4-0.2
				c-0.3-0.1-0.6-0.3-0.8-0.5c-0.2-0.3-0.3-0.6-0.3-1c0-0.4-0.1-0.9-0.1-1.5v-3.7h-1.2v-1.7h1.2v-3H-138.7z"
            />
            <motion.path
              variants={icon}
              initial="hidden"
              animate={logoDisplay}
              transition={{
                default: { duration: 1.4, ease: "easeInOut" },
                fill: { duration: 1.4, ease: [1, 0, 0.8, 1] },
              }}
              d="M-134.3,427.1c0,0.3,0,0.6,0.1,0.9c0.1,0.3,0.2,0.6,0.3,0.8s0.4,0.4,0.6,0.6c0.2,0.2,0.6,0.2,0.9,0.2
				c0.5,0,0.9-0.1,1.2-0.4c0.3-0.3,0.5-0.6,0.6-1.1h2.2c-0.1,0.6-0.3,1.1-0.6,1.5s-0.6,0.7-0.9,0.9c-0.3,0.2-0.7,0.4-1.1,0.5
				c-0.4,0.1-0.8,0.1-1.2,0.1c-0.7,0-1.4-0.1-1.9-0.4c-0.5-0.2-1-0.6-1.3-1c-0.3-0.4-0.6-0.9-0.8-1.5c-0.2-0.6-0.3-1.1-0.3-1.8
				c0-0.6,0.1-1.2,0.3-1.8c0.2-0.6,0.4-1,0.8-1.5c0.3-0.4,0.8-0.8,1.3-1c0.5-0.2,1.1-0.4,1.8-0.4c0.8,0,1.5,0.1,2,0.4
				c0.5,0.3,1,0.6,1.3,1.1c0.3,0.5,0.5,1,0.7,1.6c0.1,0.6,0.2,1.3,0.1,2H-134.3z M-130.2,425.5c-0.1-0.6-0.2-1.1-0.5-1.4
				c-0.3-0.3-0.7-0.5-1.3-0.5c-0.7,0-1.2,0.2-1.5,0.5c-0.3,0.3-0.5,0.8-0.6,1.4H-130.2z"
            />
          </g>
        </g>
      </g>
    </motion.svg>
    </LogoWrapper>
  );
};
