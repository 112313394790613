import styled from "styled-components";
import { motion } from "framer-motion";

export const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  background-color: rgba('#fff', 0.4)
`;

export  const StatusBar = styled.div`
  display: flex;
  padding-top: 10px;
  background: ${(props) => props.primary};
  width: 100%;
  z-index:1;
`;

export const ButtonStyle = styled.button`
  font-size: 18px;
  padding: 10px 20px;
  margin-top: 20px;
  border: none;
  border-radius: 5px;
  background-color: #e0e0e0;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  box-sizing: border-box;
  &:hover {
    background-color: #c7c7c7;
  }
`;

export const Background = styled.div`
  width: 100%;
  height: 100vh;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.primary};
`;



export const LogoWrapper = styled(motion.div)`
  max-width:80%;
 width: 100%;
`


export const BackgroundImage = styled(motion.div)`
  position: fixed;
  z-index: 0;
  overflow: hidden;
  background-image: url("${(props) => props.image}");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: black;
  width: 100%;
  height: 100%;
  background-position: center;
`;
