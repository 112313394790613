import { motion, AnimatePresence } from "framer-motion";
import { TeamSelect } from "./TeamSelect";
import { Confirm } from "./Confirm";
import React, { useState } from "react";
import styled from "styled-components";

const modalVariants = {
  opened: {
    opacity: 1,
    transition: { duration: 0.2 }
  },
  closed: {
    opacity: 0,
    transition: { duration: 0.2 }
  },
};

export const ModalWrapper = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ModalContent = styled(motion.div)`
  padding: 20px;
  width: 100%;
  height: 100%;
  font-size: 30px;
  position: relative;
  color: white;
  text-align: center;
  background-color: #f27c00;
  font-family: "CheltnhmitcBt";
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
`;

export const Modal = ({ selectedMember, cancelSelection }) => {
  const [isConfirmed, setIsConfirmed] = useState(false);
  return (
    <ModalWrapper as={motion.div}
      key={'modal'}
      variants={modalVariants}
      initial={"closed"}
      animate={"opened"}
    >
      <ModalContent
        as={motion.div}
      >
        <AnimatePresence mode={"wait"}>
          {!isConfirmed ? (
            <Confirm
              key="confirm"
              selectedMember={selectedMember}
              setIsConfirmed={() => setIsConfirmed(!isConfirmed)}
              cancelSelection={() => cancelSelection()}
              isConfirmed={isConfirmed}
            />
          ) : (
            <TeamSelect key="team" selectedMember={selectedMember} />
          )}
        </AnimatePresence>
      </ModalContent>
    </ModalWrapper>
  );
};
